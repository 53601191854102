.leaflet-container-headline-map {
    height: 100%;
    min-height: 30vh;
    width: 100%;
    margin: 0 auto;
}

.leaflet-control-attribution {
    display: none;
}

.leaflet-legend {
    padding: 2px;
    background-color: #FFFFFF;
}

.circle-area {
    height: 20px;
    width: 20px;
    border: 3px solid rgba(255, 0, 0, 1);;
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 50%;
    display: inline-block;
}


ul.no-bullets {
    list-style-type: none;
    padding: 0;
    margin: 0;
}